<template>
  <div class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-block card-stretch card-height blog blog-detail">
            <div class="card-header d-flex justify-content-center">
              <div class="header-title">
                <h5 class="card-title text-success">Omnichannel</h5>
              </div>
            </div>

            <div class="card-body">
              <div class="col-sm-12 px-lg-5">
                <p>{{ $t('label.uploadIDPhoto') }}</p>
              </div>
              <div class="col-sm-12 px-lg-5 d-flex justify-content-center">
                <img :src="form.apply_identity" class="rounded suer-l-img" alt="Responsive image" />
              </div>
              <div class="col-sm-12 px-lg-5 d-flex justify-content-center">
                <vue-core-image-upload @click="cLoder" :crop="false" @imageuploaded="imageuploaded" @imageuploading="imageuploading" :headers="{ authorization: token }"
                  @imagechanged="imagechanged({ type: 'apply_identity' })" :data="{ type: 'apply_identity' }" :max-file-size="commomData.uploadOption.maxFileSize" :url="commomData.uploadOption.url"
                  :inputAccept="commomData.uploadOption.accept" :credentials="false" inputOfFile="file" text=".">
                  <button type="button" class="shadow btn btn-primary rounded-pill mb-3 mt-3 me-1">
                    <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                    ＋
                  </button>
                </vue-core-image-upload>
              </div>
            </div>
            <hr />
            <div class="card-body">
              <div class="col-sm-12 px-lg-5">
                <p>{{ $t('label.pleaseUploadAPassportPhoto') }}</p>
              </div>
              <div class="col-sm-12 px-lg-5 d-flex justify-content-center">
                <img :src="form.apply_photograph" class="img-fluid suer-l-img" alt="Responsive image" />
              </div>
              <div class="col-sm-12 px-lg-5 d-flex justify-content-center">
                <vue-core-image-upload @click="cLoder" :crop="false" @imageuploaded="imageuploaded" @imageuploading="imageuploading" :headers="{ authorization: token }"
                  @imagechanged="imagechanged({ type: 'apply_photograph' })" :data="{ type: 'apply_photograph' }" :max-file-size="commomData.uploadOption.maxFileSize" :url="commomData.uploadOption.url"
                  :inputAccept="commomData.uploadOption.accept" :credentials="false" inputOfFile="file" text=".">
                  <button type="button" class="shadow btn btn-primary rounded-pill mb-3 mt-3 me-1">
                    <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                    ＋
                  </button>
                </vue-core-image-upload>
              </div>
            </div>
            <form>
              <div class="card-body">
                <div class="col-sm-12 px-lg-5">
                  <p>{{ $t('label.yourSocialAccount') }}</p>
                </div>
                <div class="col-sm-12 px-lg-5 d-flex justify-content-center input-group">
                  <textarea v-model="form.apply_content" class="form-control" rows="5" :class="{ 'is-invalid': v$.form.apply_content.$error }" :placeholder="
                      $t('label.pleaseProvideYourSocialLinksSuchAsFBYoutub')
                    "></textarea>
                  <span v-if="v$.form.apply_content.$error" class="invalid-feedback">{{ $t('messages.required') }}</span>
                </div>
              </div>
              <hr />
              <div class="card-body">
                <div class="d-flex justify-content-center">
                  <router-link to="/" class="shadow btn btn-outline-primary rounded-pill mb-3 me-1">
                    {{ $t('button.cancel') }}</router-link>
                  <button @click="onSubmit()" type="button" class="shadow btn btn-primary rounded-pill mb-3 me-1">
                    <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                    {{ $t('button.send') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apply } from '@/api/apply'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import VueCoreImageUpload from 'vue-core-image-upload'

export default {
  name: 'VerifyLicense',
  components: {
    'vue-core-image-upload': VueCoreImageUpload
  },
  setup() {
    const v$ = useVuelidate()
    return { v$ }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  data() {
    return {
      commomData: commomData(),
      form: {
        apply_identity: require('@/assets/images/small/user-1.png'),
        apply_photograph: require('@/assets/images/small/user-2.png'),
        apply_content: ''
      },
      signCode: {
        apply_identity: '',
        apply_photograph: ''
      }
    }
  },
  validations() {
    return {
      form: {
        apply_content: { required }
      },
      signCode: {
        apply_identity: { required },
        apply_photograph: { required }
      }
    }
  },
  methods: {
    cLoder() {
      this.commomData.btnLoader = true
      setTimeout(() => {
        this.commomData.btnLoader = false
      }, 3000)
    },
    imageuploaded(apiRes, res) {
      console.log(res)
      this.form[res.type] = apiRes.file.file_url
      this.signCode[res.type] = apiRes.file.file_path
      this.commomData.btnLoader = false
    },
    imageuploading(res) {
      console.log(res)
      this.commomData.btnLoader = true
    },
    imagechanged(res) {
      console.log(res)
      this.form.type = res.type
    },
    errorhandle(res) {
      alert(res)
    },
    onSubmit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      debugger
      this.commomData.btnLoader = true
      const p = {
        apply_content: this.form.apply_content,
        type: 'apply_photograph',
        apply_identity: this.signCode.apply_identity,
        apply_photograph: this.signCode.apply_photograph
      }
      apply(p)
        .then(res => {
          console.log(res)
          //創作者驗證以後不導向金流申請，移除導量金流申請步驟
          // this.$swal.mixin().fire({
          //   icon: 'success',
          //   text: res.message
          // })

          // setTimeout(() => {
          //   this.$router.push({ name: 'auth1.specialStore' })
          // }, 3000)
          this.$swal.mixin().fire({
            icon: 'success',
            text: this.$t('label.moderated')
          })
          setTimeout(() => {
            this.$router.push({ name: 'social.list' })
            this.commomData.btnLoader = false
          }, 3000)
        })
        .catch(err => {
          this.commomData.btnLoader = false
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    }
  }
}
</script>
<style lang="css">
.suer-l-img {
  width: 330px;
  height: 220px;
}
</style>
